<template>
    <div>
        <Header />
         <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/commingsoon">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/Concentration.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Electronic Concentration Analysis
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="ioniccontaminationtesting()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/contamination.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Ionic Contamination Testing
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="spi()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/spi.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                SPI
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/inspection_test/aoi">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/aoi.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                AOI
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="inspectionxray()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/xray.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              <br>
                INSPECTION X RAY
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/inspection_test/microscope">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/microscope.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Microscope
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="testsolution()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:100%; max-width:95%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/test.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Test Solution 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="thermalprofiling()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/mesure.jpg"
              alt="Card image cap"
            />
            <br>

            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Thermal Profiling
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/inspection_test/heat_cold_testing">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/temperature-testing-chambers-ur-332.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Heat Cold Testing
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="testmesure()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:100%; max-width:80%;margin:auto"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/testmesure1.jpg"
              alt="Card image cap"
            />
            <br><br>
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Test and Measurement
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="icprogramming()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/inspection_test/programing.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                IC programmaing
              </h4>
            </div>
          </div>
          </a>
        </div>
       
        <!-- end col -->
      </div>
    </div>
        <Footer />
    </div>
</template>
<script>

import Header from '../../components/Header.vue';

import Footer from '../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  methods: {
     ioniccontaminationtesting(){
      window.location.href = "https://www.dct.cleaning/measuring-instruments-process-control/";
    },
    spi(){
      window.location.href = "https://www.sakicorp.com/en/product/3dspi/";
    },
     inspectionxray(){
      window.location.href = "https://www.sakicorp.com/en/product/3daxi/";
    },
    testsolution(){
      window.location.href = "https://www.seica.com/solutions/";
    },
    thermalprofiling(){
      window.location.href = "https://gp-ics.com/products/measuring-systems/";
    },
     icprogramming(){
      window.location.href = "https://www.dediprog.com/category/universal-programmer";
    },
    testmesure(){
      window.location.href = "https://www.seica.com/solutions/";

    },
  },
  
};
</script>